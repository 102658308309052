
//
// Make Bootstrap components usable from templates (like "bootstrap.Modal(..)")
//
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

//
// Make Bootstrap components usable inside modules (directly through component name)
// You may disable unused components to lighten builded JS file
//
import {
    Alert,
    Button,
    Carousel,
    Collapse,
    Dropdown,
    Modal,
    Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip,
} from "bootstrap/dist/js/bootstrap.bundle.js";

import SimpleLightbox from "simplelightbox/dist/simple-lightbox.esm";

//
// NOTE: Sample how to instanciate a Bootstrap component object.
//
// var myModal = new Modal(document.getElementById("exampleModalDefault"));
// myModal.show();

import {
    navswipe,
    horscrollsw,
    brandCarousel,
} from "./components/hor-scroll-swiper";
import { swipeBan, swipeAnim, swipeNav } from "./components/add-swiper";
import { burgerMenu } from "./components/burgermenu";
import { masGrid } from "./components/masgrid"
// Disabled for now
// import { fobival } from "./components/fobi-valid";

import { imgToModal } from "./components/btn-modal";
import { captcha } from "./components/captcha";

/**
 * Run the application
 */

const run = () => {
    navswipe();
    brandCarousel();
    horscrollsw();
    swipeBan(".hero-slider .swiper-container", ".nav-slider .swiper-container",);
    //swipeNav();
    swipeAnim();
    burgerMenu();
    imgToModal();
    captcha();
    masGrid();
    var modalalert = document.getElementById("HomeModal");
    var modalshown = localStorage.getItem('isshow');
    
    if (modalshown ==  'done') {} else {
        localStorage.setItem('isshow', 'done');
        if (modalalert) {
            var myModal = new bootstrap.Modal(modalalert, {});
            document.onreadystatechange = function () {
                myModal.show();
            };
        }
    }

};


document.addEventListener("DOMContentLoaded", function () {
    // splide JS
    var $gallery = new SimpleLightbox('#lightbox-gallery a', {});
    run();
});
